import React from "react"
import styled from "styled-components"
import { Link, FormattedMessage } from "gatsby-plugin-intl"
import Language from "./language"
import MobileNavigation from "./mobileNavigation"
import tw from "twin.macro"

import logo from "../images/neuejob-logo.svg"
import logoMobile from "../images/neuejob-logo-mobile.svg"

const activeClassName = "text-black"

const NavigationWrapper = styled.header`
  ${tw`w-full z-20 fixed bg-white`};
  transition-duration: 500ms;
`
const Navigation = styled.div`
  ${tw` flex items-center  justify-between flex-wrap py-6 lg:py-8 px-5 xxl:px-20`};
`
const Logo = styled(Link)`
  ${tw`text-2xl font-bold text-black`};
`
const LogoImg = styled.img`
  ${tw`h-7 hidden md:block md:h-auto`};
`
const LogoImgMobile = styled.img`
  ${tw`block md:hidden md:h-auto`};
`

const DesktopNav = styled.nav`
  ${tw`flex-grow relative hidden lg:flex lg:items-center justify-end w-auto`}
`

const MobileNav = styled.nav`
  ${tw`flex items-center lg:hidden `}
`

const NavLink = styled(Link).attrs({ activeClassName })`
  ${tw` md:py-0 md:px-2 lg:ml-10 xl:ml-12 text-lg font-semibold self-center text-navigation hover:text-navigationHover duration-75 `};
  &.${activeClassName} {
    ${tw` text-title`};
  }
`

const NavLinkButton = styled(Link)`
  ${tw`rounded-full lg:ml-10 xl:ml-12 py-3 px-8 text-lg font-semibold text-dark bg-primary duration-75 hover:bg-primaryHovered cursor-pointer mr-5 lg:mr-0`};
`

const EmptySpace = styled.div`
  ${tw`w-full bg-white`};
  height: 115px;
  @media only screen and (max-width: 1023px) {
    height: 99px;
  }
`

class Nav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileMenuActive: false,
    }
  }

  toggle = () => {
    this.setState({
      mobileMenuActive: !this.state.mobileMenuActive,
    })
  }

  render(props) {
    return (
      <>
        <EmptySpace id="top-nav"></EmptySpace>
        <NavigationWrapper
          style={{
            boxShadow: this.props.navShadow
              ? "0px 15px 54px 0px rgba(0, 9, 25, 0.14)"
              : "none",
          }}
        >
          <Navigation>
            <Logo to="/">
              <LogoImg src={logo} alt="NeueJob"></LogoImg>
              <LogoImgMobile src={logoMobile} alt="NeueJob"></LogoImgMobile>
            </Logo>
            <MobileNav>
              <NavLinkButton to="/contact">
                <FormattedMessage id="navigation.contact" />
              </NavLinkButton>
              <MobileNavigation onClick={this.toggle} />
            </MobileNav>
            <DesktopNav>
              <Language />
              {/* <NavLink to="/about">
              <FormattedMessage id="navigation.about" />
            </NavLink> */}
              <NavLink to="/offers">
                <FormattedMessage id="navigation.offers" />
              </NavLink>
              <NavLink to="/faq">
                <FormattedMessage id="navigation.faq" />
              </NavLink>
              <NavLinkButton to="/contact">
                <FormattedMessage id="navigation.contact" />
              </NavLinkButton>
            </DesktopNav>
          </Navigation>
        </NavigationWrapper>
      </>
    )
  }
}

export default Nav
