import React from "react"
import styled from "styled-components"
import tw from "twin.macro"

import logo from "../images/neuejob-logo-white.svg"
import facebook from "../images/icons/facebook.svg"
import instagram from "../images/icons/instagram.svg"
import facebookSmall from "../images/icons/facebook-small.svg"
import instagramSmall from "../images/icons/instagram-small.svg"

const FooterWrapper = styled.div`
  ${tw`bg-dark w-full`};
`

const FooterSection = styled.section`
  ${tw`flex-col sm:flex-row flex items-center px-5 py-8 max-w-default justify-between`}
  margin: 0 auto;
`

const SMIcons = styled.div`
  ${tw`text-white flex`}
`
const SMLink = styled.a`
  ${tw`opacity-23 pl-18px sm:pl-5 hover:opacity-40 duration-200`}
`
const SMIcon = styled.img`
  ${tw`hidden sm:block`}
  max-width: none !important;
`
const SMIconSmall = styled.img`
  ${tw`block sm:hidden`}
  max-width: none !important;
`

const Address = styled.div`
  ${tw`text-white opacity-30 font-semibold  pl-10 pr-5 text-center justify-center py-5 sm:py-0`}
`

const LogoFooter = styled.img`
  @media only screen and (max-width: 767px) {
    width: 112px;
  }
`
const CompanyLink = styled.div`
  ${tw`flex justify-center font-normal mt-2`}
  a {
    margin-left: 10px;
  }
`

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterSection>
        <LogoFooter src={logo} alt="NeueJob Logo"></LogoFooter>
        <Address>
          SITINO s. r. o., Partizánska 680/1, 058 01 Poprad, Slovakia
          <br />{" "}
          <CompanyLink>
            web created by
            <a href="https://bananappeal.com/" target="_blank" rel="noopener noreferrer">
              <svg
                width="110"
                height="21"
                viewBox="0 0 110 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="4.56201" width="3" height="3" fill="#fff" />
                <rect y="9.12451" width="3" height="3" fill="#fff" />
                <rect y="13.6865" width="3" height="3" fill="#fff" />
                <rect
                  x="4.56219"
                  y="4.56201"
                  width="3"
                  height="3"
                  fill="#fff"
                />
                <rect width="3" height="3" fill="#fff" />
                <rect
                  x="4.56219"
                  y="13.6865"
                  width="3"
                  height="3"
                  fill="#fff"
                />
                <rect
                  x="9.12445"
                  y="4.56201"
                  width="3"
                  height="3"
                  fill="#fff"
                />
                <rect
                  x="9.12445"
                  y="9.12451"
                  width="3"
                  height="3"
                  fill="#fff"
                />
                <rect
                  x="9.12445"
                  y="13.6865"
                  width="3"
                  height="3"
                  fill="#fff"
                />
                <path
                  d="M20.789 16.9506C20.2459 16.9506 19.7539 16.8413 19.3132 16.6227C18.8827 16.3937 18.5445 16.0763 18.2986 15.6704L18.2678 16.7008H16.2231V4.05542H18.36V9.69119C18.9237 8.9002 19.7693 8.50471 20.8967 8.50471C21.5833 8.50471 22.1983 8.67644 22.7415 9.01989C23.2949 9.36335 23.7305 9.85771 24.0482 10.503C24.3659 11.1379 24.5248 11.8716 24.5248 12.7042C24.5248 13.4744 24.3659 14.1821 24.0482 14.8274C23.7407 15.4623 23.3 15.9774 22.7261 16.3729C22.1522 16.758 21.5065 16.9506 20.789 16.9506ZM20.3125 15.0147C20.9274 15.0147 21.4245 14.8014 21.8037 14.3747C22.1932 13.9479 22.3879 13.4015 22.3879 12.7354C22.3879 12.0589 22.1932 11.5073 21.8037 11.0806C21.4142 10.6539 20.9172 10.4405 20.3125 10.4405C19.7078 10.4405 19.2107 10.6539 18.8213 11.0806C18.4318 11.5073 18.2371 12.0589 18.2371 12.7354C18.2371 13.4015 18.4267 13.9479 18.8059 14.3747C19.1953 14.8014 19.6975 15.0147 20.3125 15.0147Z"
                  fill="#fff"
                />
                <path
                  d="M28.8457 16.9506C28.159 16.9506 27.5389 16.7788 26.9855 16.4354C26.4423 16.0919 26.0118 15.6028 25.6941 14.9679C25.3764 14.3226 25.2175 13.5837 25.2175 12.7511C25.2175 11.9809 25.3713 11.2784 25.6788 10.6435C25.9965 9.99822 26.4423 9.48303 27.0162 9.09795C27.5902 8.70246 28.2359 8.50471 28.9533 8.50471C29.4862 8.50471 29.9679 8.6192 30.3984 8.84817C30.8391 9.06673 31.1876 9.37896 31.4438 9.78486L31.4745 8.7545H33.5192V16.7008H31.4745L31.4438 15.686C31.1773 16.1023 30.8237 16.4198 30.383 16.6383C29.9423 16.8465 29.4299 16.9506 28.8457 16.9506ZM29.4299 15.0147C30.0346 15.0147 30.5316 14.8014 30.9211 14.3747C31.3105 13.9479 31.5053 13.3963 31.5053 12.7198C31.5053 12.0537 31.3105 11.5073 30.9211 11.0806C30.5419 10.6539 30.0448 10.4405 29.4299 10.4405C28.8149 10.4405 28.3127 10.6539 27.9233 11.0806C27.5441 11.5073 27.3545 12.0537 27.3545 12.7198C27.3545 13.3963 27.5492 13.9479 27.9386 14.3747C28.3281 14.8014 28.8252 15.0147 29.4299 15.0147Z"
                  fill="#fff"
                />
                <path
                  d="M35.0285 8.7545H37.0886L37.104 9.73802C37.3294 9.34253 37.642 9.04071 38.0417 8.83255C38.4517 8.61399 38.9283 8.50471 39.4715 8.50471C40.3939 8.50471 41.0959 8.79613 41.5776 9.37896C42.0696 9.95138 42.3155 10.784 42.3155 11.8768V16.7008H40.1786V12.2827C40.1786 11.0442 39.7123 10.4249 38.7797 10.4249C38.2672 10.4249 37.8675 10.6019 37.5805 10.9557C37.3038 11.2992 37.1655 11.7883 37.1655 12.4232V16.7008H35.0285V8.7545Z"
                  fill="#fff"
                />
                <path
                  d="M46.9605 16.9506C46.2738 16.9506 45.6537 16.7788 45.1003 16.4354C44.5571 16.0919 44.1266 15.6028 43.8089 14.9679C43.4912 14.3226 43.3323 13.5837 43.3323 12.7511C43.3323 11.9809 43.4861 11.2784 43.7935 10.6435C44.1113 9.99822 44.5571 9.48303 45.131 9.09795C45.705 8.70246 46.3507 8.50471 47.0681 8.50471C47.601 8.50471 48.0827 8.6192 48.5132 8.84817C48.9539 9.06673 49.3024 9.37896 49.5586 9.78486L49.5893 8.7545H51.634V16.7008H49.5893L49.5586 15.686C49.2921 16.1023 48.9385 16.4198 48.4978 16.6383C48.0571 16.8465 47.5447 16.9506 46.9605 16.9506ZM47.5447 15.0147C48.1493 15.0147 48.6464 14.8014 49.0359 14.3747C49.4253 13.9479 49.6201 13.3963 49.6201 12.7198C49.6201 12.0537 49.4253 11.5073 49.0359 11.0806C48.6567 10.6539 48.1596 10.4405 47.5447 10.4405C46.9297 10.4405 46.4275 10.6539 46.0381 11.0806C45.6589 11.5073 45.4693 12.0537 45.4693 12.7198C45.4693 13.3963 45.664 13.9479 46.0534 14.3747C46.4429 14.8014 46.94 15.0147 47.5447 15.0147Z"
                  fill="#fff"
                />
                <path
                  d="M53.1433 8.7545H55.2034L55.2188 9.73802C55.4442 9.34253 55.7568 9.04071 56.1565 8.83255C56.5665 8.61399 57.0431 8.50471 57.5863 8.50471C58.5087 8.50471 59.2107 8.79613 59.6924 9.37896C60.1844 9.95138 60.4303 10.784 60.4303 11.8768V16.7008H58.2934V12.2827C58.2934 11.0442 57.8271 10.4249 56.8945 10.4249C56.382 10.4249 55.9823 10.6019 55.6953 10.9557C55.4186 11.2992 55.2802 11.7883 55.2802 12.4232V16.7008H53.1433V8.7545Z"
                  fill="#fff"
                />
                <path
                  d="M65.0753 16.9506C64.3886 16.9506 63.7685 16.7788 63.2151 16.4354C62.6719 16.0919 62.2414 15.6028 61.9237 14.9679C61.606 14.3226 61.4471 13.5837 61.4471 12.7511C61.4471 11.9809 61.6009 11.2784 61.9083 10.6435C62.2261 9.99822 62.6719 9.48303 63.2458 9.09795C63.8198 8.70246 64.4655 8.50471 65.1829 8.50471C65.7158 8.50471 66.1975 8.6192 66.628 8.84817C67.0687 9.06673 67.4172 9.37896 67.6734 9.78486L67.7041 8.7545H69.7488V16.7008H67.7041L67.6734 15.686C67.4069 16.1023 67.0533 16.4198 66.6126 16.6383C66.1719 16.8465 65.6595 16.9506 65.0753 16.9506ZM65.6595 15.0147C66.2641 15.0147 66.7612 14.8014 67.1507 14.3747C67.5401 13.9479 67.7349 13.3963 67.7349 12.7198C67.7349 12.0537 67.5401 11.5073 67.1507 11.0806C66.7715 10.6539 66.2744 10.4405 65.6595 10.4405C65.0445 10.4405 64.5423 10.6539 64.1529 11.0806C63.7737 11.5073 63.5841 12.0537 63.5841 12.7198C63.5841 13.3963 63.7788 13.9479 64.1682 14.3747C64.5577 14.8014 65.0548 15.0147 65.6595 15.0147Z"
                  fill="#fff"
                />
                <path
                  d="M71.2581 8.7545H73.3028L73.3336 9.78486C73.8665 8.93143 74.7325 8.50471 75.9317 8.50471C76.6183 8.50471 77.2333 8.67644 77.7765 9.01989C78.3299 9.36335 78.7655 9.85771 79.0832 10.503C79.4009 11.1379 79.5598 11.8716 79.5598 12.7042C79.5598 13.4744 79.4009 14.1821 79.0832 14.8274C78.7757 15.4623 78.335 15.9774 77.7611 16.3729C77.1872 16.758 76.5415 16.9506 75.824 16.9506C75.3013 16.9506 74.8299 16.8517 74.4097 16.6539C73.9895 16.4458 73.6513 16.1544 73.395 15.7797V20.5256H71.2581V8.7545ZM75.3475 15.0147C75.9624 15.0147 76.4595 14.8014 76.8387 14.3747C77.2281 13.9479 77.4229 13.4015 77.4229 12.7354C77.4229 12.0589 77.2281 11.5073 76.8387 11.0806C76.4492 10.6539 75.9522 10.4405 75.3475 10.4405C74.7428 10.4405 74.2457 10.6539 73.8563 11.0806C73.4668 11.5073 73.2721 12.0589 73.2721 12.7354C73.2721 13.4015 73.4617 13.9479 73.8409 14.3747C74.2303 14.8014 74.7325 15.0147 75.3475 15.0147Z"
                  fill="#fff"
                />
                <path
                  d="M80.6233 8.7545H82.668L82.6987 9.78486C83.2317 8.93143 84.0977 8.50471 85.2968 8.50471C85.9835 8.50471 86.5984 8.67644 87.1416 9.01989C87.6951 9.36335 88.1307 9.85771 88.4484 10.503C88.7661 11.1379 88.9249 11.8716 88.9249 12.7042C88.9249 13.4744 88.7661 14.1821 88.4484 14.8274C88.1409 15.4623 87.7002 15.9774 87.1263 16.3729C86.5523 16.758 85.9066 16.9506 85.1892 16.9506C84.6665 16.9506 84.1951 16.8517 83.7749 16.6539C83.3547 16.4458 83.0164 16.1544 82.7602 15.7797V20.5256H80.6233V8.7545ZM84.7126 15.0147C85.3276 15.0147 85.8246 14.8014 86.2039 14.3747C86.5933 13.9479 86.788 13.4015 86.788 12.7354C86.788 12.0589 86.5933 11.5073 86.2039 11.0806C85.8144 10.6539 85.3173 10.4405 84.7126 10.4405C84.1079 10.4405 83.6109 10.6539 83.2214 11.0806C82.832 11.5073 82.6372 12.0589 82.6372 12.7354C82.6372 13.4015 82.8268 13.9479 83.206 14.3747C83.5955 14.8014 84.0977 15.0147 84.7126 15.0147Z"
                  fill="#fff"
                />
                <path
                  d="M94.1682 16.9506C93.2561 16.9506 92.4567 16.784 91.77 16.451C91.0936 16.1179 90.5657 15.634 90.1865 14.9991C89.8073 14.3538 89.6177 13.5993 89.6177 12.7354C89.6177 11.934 89.7817 11.2159 90.1097 10.581C90.4376 9.93577 90.904 9.431 91.5086 9.06673C92.1133 8.69205 92.8154 8.50471 93.6148 8.50471C94.3527 8.50471 94.9984 8.66603 95.5518 8.98867C96.1155 9.3009 96.5511 9.75363 96.8586 10.3469C97.1763 10.9401 97.3352 11.6426 97.3352 12.4544C97.3352 12.7979 97.3095 13.1101 97.2583 13.3911H91.7392C92.0057 14.5776 92.8923 15.1708 94.3988 15.1708C94.8703 15.1708 95.3264 15.124 95.7671 15.0303C96.218 14.9263 96.6229 14.7806 96.9816 14.5932L97.1968 16.2949C96.8278 16.503 96.3769 16.6643 95.8439 16.7788C95.311 16.8933 94.7524 16.9506 94.1682 16.9506ZM95.2905 11.8768C95.2393 11.3252 95.0599 10.9037 94.7524 10.6123C94.4552 10.3104 94.0555 10.1595 93.5533 10.1595C93.0614 10.1595 92.6565 10.3104 92.3388 10.6123C92.0211 10.9037 91.8161 11.3252 91.7239 11.8768H95.2905Z"
                  fill="#fff"
                />
                <path
                  d="M101.68 16.9506C100.994 16.9506 100.373 16.7788 99.82 16.4354C99.2768 16.0919 98.8464 15.6028 98.5286 14.9679C98.2109 14.3226 98.0521 13.5837 98.0521 12.7511C98.0521 11.9809 98.2058 11.2784 98.5133 10.6435C98.831 9.99822 99.2768 9.48303 99.8508 9.09795C100.425 8.70246 101.07 8.50471 101.788 8.50471C102.321 8.50471 102.802 8.6192 103.233 8.84817C103.674 9.06673 104.022 9.37896 104.278 9.78486L104.309 8.7545H106.354V16.7008H104.309L104.278 15.686C104.012 16.1023 103.658 16.4198 103.218 16.6383C102.777 16.8465 102.264 16.9506 101.68 16.9506ZM102.264 15.0147C102.869 15.0147 103.366 14.8014 103.756 14.3747C104.145 13.9479 104.34 13.3963 104.34 12.7198C104.34 12.0537 104.145 11.5073 103.756 11.0806C103.376 10.6539 102.879 10.4405 102.264 10.4405C101.649 10.4405 101.147 10.6539 100.758 11.0806C100.379 11.5073 100.189 12.0537 100.189 12.7198C100.189 13.3963 100.384 13.9479 100.773 14.3747C101.163 14.8014 101.66 15.0147 102.264 15.0147Z"
                  fill="#fff"
                />
                <path
                  d="M107.863 4.05542H110V16.7008H107.863V4.05542Z"
                  fill="#fff"
                />
              </svg>
            </a>
          </CompanyLink>
        </Address>
        <SMIcons>
          {/* <SMLink href="https://www.facebook.com/" target="_blank">
            <SMIcon src={linkedin} alt="linkedin"></SMIcon>
          </SMLink> */}
          <SMLink href="https://www.facebook.com/neuejob" target="_blank">
            <SMIcon src={facebook} alt="facebook"></SMIcon>
            <SMIconSmall src={facebookSmall} alt="facebook"></SMIconSmall>
          </SMLink>
          <SMLink href="https://www.instagram.com/neue.job" target="_blank">
            <SMIcon src={instagram} alt="instagram"></SMIcon>
            <SMIconSmall src={instagramSmall} alt="instagram"></SMIconSmall>
          </SMLink>
        </SMIcons>
      </FooterSection>
    </FooterWrapper>
  )
}

export default Footer
