import React from "react"
import {
  IntlContextConsumer,
  changeLocale,
  Link,
  FormattedMessage,
} from "gatsby-plugin-intl"
import styled from "styled-components"
import tw from "twin.macro"

import arrow from "../images/icons/arrow-down.svg"

const activeClassName = "text-black"

const Menu = styled.div`
  ${tw`flex items-center text-navigation opacity-30 text-dark font-semibold hover:opacity-100 duration-150 cursor-pointer `}
`

const NavItem = styled.a`
  ${tw`cursor-pointer font-semibold text-center px-4 py-1 hover:bg-light text-navigation`}
`

const Icon = styled.img`
  ${tw`duration-500`}
`

const Dropdown = styled.div`
  ${tw`relative inline-block`}
`

const NavWrapper = styled.div`
  ${tw`flex`}
`

const NavLink = styled(Link).attrs({ activeClassName })`
  ${tw` md:py-0 md:px-2 md:ml-5 text-lg font-semibold w-full pt-5 pb-3 text-right text-navigation hover:text-navigationHover duration-75 `};
  &.${activeClassName} {
    ${tw` text-title`};
  }
`

const MenuWrapper = styled.section`
  ${tw`absolute hidden bg-white items-center shadow-lg p-6 flex-col`}
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  border-top: 2px solid #b3b5ba;
  top: 50px;
  right: -20px;
  z-index: 1;
  min-width: 240px;
`

const LanguageWrapper = styled.div`
  ${tw`flex w-full border-t-2 border-dark mt-3 pt-5 justify-center`}
`

const MenuText = styled.p`
  ${tw`pr-4`}
`

class Language extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdown: false,
    }
  }

  componentDidMount() {
    window.addEventListener("click", this.handleClick, false)
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleClick, false)
  }

  closeDropdown = () => {
    this.setState({ dropdown: false })
  }

  showDropdown = e => {
    this.setState({
      dropdown: !this.state.dropdown,
    })
  }

  handleClick = e => {
    if (this.node.contains(e.target)) {
      return
    }
    this.closeDropdown()
  }

  render(props) {
    return (
      <NavWrapper ref={node => (this.node = node)} onClick={this.showDropdown}>
        <IntlContextConsumer>
          {({ languages, language: currentLocale }) => (
            <Dropdown>
              <Menu>
                <MenuText>menu</MenuText>
                <Icon
                  src={arrow}
                  alt="arrow"
                  style={{
                    transform: this.state.dropdown
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  }}
                ></Icon>
              </Menu>
              <MenuWrapper
                style={{
                  display: this.state.dropdown ? "flex" : "none",
                }}
              >
                {/* <NavLink to="/about"><FormattedMessage id="navigation.about" /></NavLink> */}
                <NavLink to="/offers">
                  <FormattedMessage id="navigation.offers" />
                </NavLink>
                <NavLink to="/faq">
                  <FormattedMessage id="navigation.faq" />
                </NavLink>
                <NavLink to="/contact">
                  <FormattedMessage id="navigation.contact" />
                </NavLink>
                <LanguageWrapper>
                  <NavItem
                    style={{
                      color: currentLocale === "sk" ? `#000919` : `#B3B5BA`,
                    }}
                    onClick={() => changeLocale("sk")}
                  >
                    SK
                  </NavItem>
                  <NavItem
                    style={{
                      color: currentLocale === "en" ? `#000919` : `#B3B5BA`,
                    }}
                    onClick={() => changeLocale("en")}
                  >
                    EN
                  </NavItem>
                  {/* <NavItem
                    style={{
                      color: currentLocale === "de" ? `#000919` : `#B3B5BA`,
                    }}
                    onClick={() => changeLocale("de")}
                  >
                    DE
                  </NavItem> */}
                </LanguageWrapper>
              </MenuWrapper>
            </Dropdown>
          )}
        </IntlContextConsumer>
      </NavWrapper>
    )
  }
}

export default Language
