import React from "react"

// import Footer from './footer'
import Navigation from "./navigation"
import Footer from "./footer"
import "../styles/index.scss"
import styled from "styled-components"
import tw from "twin.macro"

import PropTypes from "prop-types"
import { injectIntl } from "gatsby-plugin-intl"

const Container = styled.div`
  ${tw`min-h-screen flex flex-col overflow-x-hidden items-center`};
`
const Main = styled.main`
  ${tw`flex-1 w-full`}
`

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      navShadow: false,
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  handleScroll = () => {
    let navShadow = false
    if (window.scrollY > 1) {
      navShadow = true
    }
    this.setState({ navShadow: navShadow })
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  render() {
    return (
      <Container>
        <Navigation navShadow={this.state.navShadow}></Navigation>
        <Main> {this.props.children}</Main>
        <Footer></Footer>
      </Container>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default injectIntl(Layout)
