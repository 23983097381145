import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"
import styled from "styled-components"
import tw from "twin.macro"

import arrow from "../images/icons/arrow-down.svg"

const Selectedlanguage = styled.div`
  ${tw`text-dark text-lg font-medium flex cursor-pointer`}
`

const NavItem = styled.a`
  ${tw`cursor-pointer font-semibold text-center px-4 py-1 hover:bg-light rounded text-navigation`}
`

const Icon = styled.img`
  ${tw`duration-200`}
`

const Dropdown = styled.div`
  ${tw`relative inline-block`}
`

const NavWrapper = styled.div`
  ${tw`border-r-2 flex border-dark pr-6 xl:pr-8`}
`

const LanguagesWrapper = styled.section`
  ${tw`absolute hidden bg-white shadow-lg rounded flex-col`}
  min-width: 68px;
  z-index: 1;
`
const Locale = styled.p`
  ${tw`pr-4 uppercase`}
`

class Language extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdown: false,
    }
  }

  componentDidMount() {
    window.addEventListener("click", this.handleClick, false)
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleClick, false)
  }

  closeDropdown = () => {
    this.setState({ dropdown: false })
  }

  showDropdown = e => {
    this.setState({
      dropdown: !this.state.dropdown,
    })
  }

  handleClick = e => {
    if (this.node.contains(e.target)) {
      return
    }
    this.closeDropdown()
  }

  render(props) {
    return (
      <NavWrapper ref={node => (this.node = node)} onClick={this.showDropdown}>
        <IntlContextConsumer>
          {({ languages, language: currentLocale }) => (
            <Dropdown>
              <Selectedlanguage>
                <Locale>{currentLocale}</Locale>
                <Icon
                  src={arrow}
                  alt="arrow"
                  style={{
                    transform: this.state.dropdown
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  }}
                ></Icon>
              </Selectedlanguage>
              <LanguagesWrapper
                style={{
                  display: this.state.dropdown ? "flex" : "none",
                }}
              >
                <NavItem
                  style={{
                    color: currentLocale === "sk" ? `#000919` : `#B3B5BA`,
                  }}
                  onClick={() => changeLocale("sk")}
                >
                  SK
                </NavItem>
                <NavItem
                  style={{
                    color: currentLocale === "en" ? `#000919` : `#B3B5BA`,
                  }}
                  onClick={() => changeLocale("en")}
                >
                  EN
                </NavItem>
                {/* <NavItem
                  style={{
                    color: currentLocale === "de" ? `#000919` : `#B3B5BA`,
                  }}
                  onClick={() => changeLocale("de")}
                >
                  DE
                </NavItem> */}
              </LanguagesWrapper>
            </Dropdown>
          )}
        </IntlContextConsumer>
      </NavWrapper>
    )
  }
}

export default Language
